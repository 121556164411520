import React, { useEffect } from "react"

import Layout from "../components/layout/layout"
import SEO from '../components/seo'

const PrivacyPolicyPage = () => {
  useEffect(() => {
    const body = document.querySelector('body')
    const footer = document.querySelector('footer')

    body.classList.remove('black')
    footer.classList.remove('black')

  })

  return(
    <Layout>
      <SEO title="Política de Privacidad"/>
      <div className="privacy-notfound">

        <div className="privacy-container">
          <h1>AVISO DE PRIVACIDAD PUBLIXLED SA DE CV</h1>

          <p>En cumplimiento de lo dispuesto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en lo sucesivo la “Ley”), su respectivo Reglamento y otras disposiciones nacionales e internacionales que resulten aplicables, se da a conocer a nuestros clientes, proveedores, prestadores de servicios, empleados, interesados en formar parte de nuestro equipo y a cualquier otra persona titular de datos personales, el siguiente “Aviso de Privacidad”.</p>
          <p>PUBLIXLED SA DE CV (en lo sucesivo “CASA VASALTO”), una agencia de publicidad independiente con domicilio en Lateral Vía Atlixcayotl 5208 Piso 14-D Tlaxcalancingo, San Andrés Cholula Pue. CP. 72810, es responsable de recabar sus datos personales, del uso que se dé a los mismos y de su protección.</p>
          <p>PublixLed pone a su disposición el presente “Aviso de Privadidad” a efecto de garantizar su derecho a la autodeterminación informativa, privacidad, respeto y obligaciones que tiene con relación al uso adecuado de la información de aquellas personas que nos proporcionan datos personales.</p>
          <p>Recabaremos sus datos personales a través de los formatos de solicitud que utilice PublixLed, de manera personal en nuestras oficinas, o eventos y/o a través de cualquier medio electrónico. Solicitaremos los datos personales que sean necesarios para la adecuada prestación de nuestros servicios y para cumplir con las obligaciones legales a las que nos encontramos sujetos.</p>
          <p>PublixLed reconoce que todos los datos personales que se encuentren en su poder han sido proporcionados de manera libre y voluntaria por sus titulares y/o han sido adquiridos de forma legítima a través de medios legales idóneos bajo el principio de privacidad y confidencialidad de la información, al respecto se le informa lo siguiente:</p>

          <h2>Datos personales que recabamos y uso de los mismos</h2>
          <p>En virtud de lo anterior, y de conformidad con las disposiciones legales aplicables, se hace de su conocimiento que requerimos obtener los datos personales para las finalidades que se describen a continuación:</p>

          <h3>- Clientes</h3>
          <p>Nombre y datos de contacto, tales como dirección, teléfono, teléfono de oficina, número de fax, teléfono celular y correo electrónico entre otros; datos laborales, tales como ocupación, lugar de trabajo, cargo, entre otros, e imágenes, fotografías, sonidos, voz y video. Adicionalmente recabaremos ciertos datos personales considerados como Datos Personales Sensibles. Para las finalidades informadas en el presente Aviso de Privacidad utilizaremos los siguientes Datos Personales Sensibles, que requieren de especial protección, tales como (i) datos ideológicos, (ii) opiniones políticas, (iii) afiliación sindical, (iv) salud, (vi) origen étnico o racial, entre otros.</p>
          <p>Los datos que nos proporcione serán utilizados para proveer los servicios que brindamos con base en las instrucciones del cliente. Entre estos se encuentran; la elaboración de encuestas, estudios de mercado para fines estadísticos y académicos, dar cumplimiento a obligaciones contraídas con nuestros clientes, realizar estudios internos sobre hábitos de consumo; y para evaluar la calidad del servicio que brindamos.</p>

          <h3>- Proveedores y prestadores de servicios</h3>
          <p>Nombre y datos de contacto, tales como dirección, teléfono, teléfono de oficina, número de fax, teléfono celular y correo electrónico entre otros; datos laborales, tales como ocupación, lugar de trabajo, cargo, entre otros, e imágenes, fotografías, sonidos, voz y video; adicionalmente requeriremos datos patrimoniales, tales como su Registro Federal de Contribuyentes y datos fiscales para facturación, datos bancarios y números de cuenta, entre otros.</p>
          <p>Los datos personales que recopilemos se utilizarán para la facturación, pago y todos los fines vinculados con la relación comercial que celebremos con nuestros proveedores y prestadores de servicios.</p>

          <h3>- Empleados e interesados en formar parte de nuestro equipo</h3>
          <p>Nombre y datos de contacto, tales como dirección, teléfono, teléfono de oficina, número de fax, teléfono celular y correo electrónico entre otros; datos laborales, tales como ocupación, lugar de trabajo actual y anterior, cargo, entre otros, e imágenes, fotografías, sonidos, voz y video; adicionalmente recabaremos ciertos datos personales considerados como Datos Personales Sensibles, incluyendo datos de salud, características personales y datos académicos, entre otros.</p>
          <p>Los datos que nos sean proporcionados se utilizarán primordialmente y según corresponda para todos los fines vinculados con reclutamiento, selección y verificación de referencias.</p>
          <p>Los datos personales que recabamos de los empleados son necesarios para llevar a cabo procesos internos del área de recursos humanos dentro PublixLed, entre los que se encuentran contar con la información de contacto de los empleados, así como antecedentes laborales y académicos e información necesaria para cumplir con las obligaciones jurídicas derivadas de la relación laboral que mantiene PublixLed con sus empleados ante diversas autoridades entre las que se encuentran de manera enunciativa, más no limitativa, el Instituto Mexicano del Seguro Social, el Instituto del Fondo Nacional de la Vivienda para los Trabajadores, la Comisión Nacional del Sistema de Ahorro para el Retiro y el Sistema de Administración Tributaria.</p>

          <h3>Otros titulares de datos personales</h3>
          <p>Dependiendo de la relación con PublixLed, se podrán recabar entre otros, nombre y datos de contacto, tales como dirección, teléfono, teléfono de oficina, número de fax, teléfono celular y correo electrónico entre otros; datos laborales, tales como ocupación, lugar de trabajo, cargo, entre otros, e imágenes, fotografías, sonidos, voz y video; adicionalmente requeriremos datos patrimoniales, tales como su clave de Registro Federal de Contribuyentes y datos fiscales para facturación, datos bancarios y números de cuenta, entre otros.</p>
          <p>En caso de que usted nos proporcione datos personales de terceros, usted garantiza y es responsable de que los terceros aceptaron dicha transferencia.</p>

          <h2>Acceso, Rectificación, Cancelación y Oposición de los datos personales</h2>
          <p>Para la PublixLed es muy importante contar con su información actualizada. Para ello, usted es responsable de que los Datos Personales proporcionados a PublixLed sean veraces y se hace responsable de comunicar cualquier modificación en los mismos.</p>
          <p>Usted tiene puede ejercer los siguientes derechos en relación con sus datos personales:</p>

          <ol>
            <li>Acceso: Usted tiene derecho de acceder a sus datos personales que obren en poder de PublixLed, así como conocer el Aviso de Privacidad al que están sujetos sus datos personales.</li>
            <li>Rectificación: En caso de que alguno de sus datos sea inexacto, cambie o se encuentre equivocado, usted tendrá el derecho de solicitar su rectificación. Le pedimos considere que, por su protección y beneficio, le solicitamos nos indique a qué datos personales se refiere, la corrección que haya de realizarse y presente, en caso de ser necesario, documentación que acredite las correcciones a los datos que deseé rectificar.</li>
            <li>Cancelación: Puede solicitar que se cancelen los datos personales que PublixLed conserve en su expediente una vez que haya concluido nuestra relación, con excepción de aquella información que derivada de una obligación legal deba ser conservada por un plazo mayor de tiempo.</li>
            <li>Oposición: Si usted no desea que PublixLed trate sus datos personales para alguna de las finalidades descritas anteriormente, usted podrá solicitarlo en todo momento.</li>
          </ol>

          <p>Para ejercer el derecho de acceder, rectificar y cancelar sus datos personales, así como de oponerse al tratamiento de los mismos o revocar su consentimiento, es necesario que se ponga en contacto con nosotros en la siguiente cuenta electrónica direcciongeneral@casavasalto.com@casavasalto.com.</p>

          <p>Su solicitud deberá contener la siguiente información: (i) nombre completo, (ii) derecho que desee ejercer, (iii) datos que requiera acceder, ratificar, cancelar u oponerse a su tratamiento, (iv) en caso de que solicite la rectificación o actualización de sus datos, deberá proporcionar el dato que sustituirá al anterior; (v) en el supuesto de oposición al tratamiento de sus datos, deberá indicar el dato personal y el uso al cual se opone; (vi) en caso de cancelación o acceso, deberá indicar el dato a cancelar o acceder, según sea el caso. Asimismo, deberá acreditar su identidad acompañando copia simple de la identificación oficial del titular; o de su representante y documento con que acredite éste carácter.</p>

          <h2>Transferencia de sus datos personales</h2>
          <p>Asimismo, le informamos que sus datos personales pueden ser transferidos y tratados dentro y fuera del país, por personas distintas a PublixLed. En ese sentido, su información puede ser compartida con personas o entidades dentro y fuera de la AGENCIA con las que tengamos una relación profesional, de negocios y/o sean proveedores de bienes o servicios. Con el fin de que dichas personas realicen diversas actividades o presten servicios a nombre de PublixLed y/o de usted, y/ o con autoridades que así lo requieran con el único fin de cumplir con las obligaciones derivadas de las leyes y/o tratados internacionales, así como con el objeto de cumplir los fines para los cuales se han recabado sus datos.</p>
          <p>Al aceptar los términos de este “Aviso de Privacidad” usted autoriza de forma expresa a PublixLed para transferir sus datos personales a dichos terceros, sean nacionales o extranjeros. Cualquier transferencia de datos estará sujeta a los fines señalados en el presente “Aviso de Privacidad”.</p>
          <p>Se hace de su conocimiento que PublixLed cuenta con los soportes físicos y electrónicos, así como con las políticas y medidas de seguridad físicas, técnicas y administrativas para proteger y limitar el uso o divulgación de sus datos personales.</p>

          <h2>Modificaciones al Aviso de Privacidad</h2>
          <p>Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente “Aviso de Privacidad”, para la atención de novedades legislativas o jurisprudenciales, políticas internas, nuevos requerimientos para la prestación u ofrecimiento de nuestros servicios y prácticas del mercado. Estas modificaciones estarán disponibles a través nuestra página de Internet, www.casavasalto.com y/o por cualquier otro medio autorizado.</p>

          <h2>Quejas y denuncias</h2>
          <p>Si usted considera que su derecho de protección de datos personales ha sido lesionado por alguna conducta de nuestros empleados, nuestras actuaciones, respuestas o presume que en el tratamiento de sus datos personales existe alguna violación a las disposiciones previstas en la Ley, podrá interponer la queja o denuncia correspondiente ante el IFAI, para mayor información visite www.ifai.org.mx.</p>
          <p>Para cualquier duda o aclaración en relación con los datos personales proporcionados, puede contactarnos en el siguiente correo electrónico: direcciongeneral@casavasalto.com, o bien, comunicarse a los teléfonos (222) 4310 227 o (222) 688 512. La fecha de la última actualización al presente “Aviso de Privacidad”: [enero de 2021]</p>
        </div>
      </div>

    </Layout>
  )
}

export default PrivacyPolicyPage
